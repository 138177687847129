import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography, Autocomplete, CircularProgress} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { ConnectWithoutContact, Search } from '@mui/icons-material';
import DateRangePicker from '../../customlib/DateRangePicker';

// 00.00 - 03.00
// 03.01 - 06.00
// 06.01 - 09.00
// 09.01 - 12.00
// 12.01 - 16.00
// 16.01 - 18.00
// 18.01 - 21.001
// 21.01 - 00.0
class ContactLeadsReportHourly extends Component {
    state = { 
        contactLeads : [],
        contactLeadsAll : [],
        contactLeadsHourly: [],
        contactLeads24Hours: [],
        contactLeadsHourlyShift: [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        // startDate: moment(new Date()).subtract(1, 'day').format("YYYY-MM-DD"),
        // endDate: moment(new Date()).subtract(1, 'day').format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        value: '0',
        dateRangeValue: [null,null],
        isLoading: false,
        loginLogs: []
    }
    
    componentDidMount() {
      this.setState(
        {appProfileId: Global.getAppProfile()._id}, ()=>{
          this.getContactLeads();
        }
      );
    }

    getContactLeads = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        this.setState(
          {
            contactLeadsHourly: [],
            contactLeadsHourlyShift: [],
            isLoading: true
          }
        );

        axios
          .post(Api.getContactLeads(), {
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              appProfileId: this.state.appProfileId
          }, Api.getRequestHeader())
          .then((response) => {
            console.log("CONTACTLEADS : ", response.data);
            console.log("USERDATA : ", "wkwkwk");
            
            var tmpContactLeads = response.data.data;
            
            if(userData.admin === false){
                tmpContactLeads = tmpContactLeads.filter(element => element.salesId === userData.user.id);
            }

            var tmpContactLeadsAll = [];
            var tmpContactLeadsHourly = [
              {
                id: "24000300",
                label: "24:00 - 03:00",//2400 - 2700 am
                count: 0,
                detail: []
              },
              {
                id: "03010600",
                label: "03:01 - 06:00",//2701 - 3000 am
                count: 0,
                detail: []
              },
              {
                id: "06010900",
                label: "06:01 - 09:00",//3001 - 3300 am
                count: 0,
                detail: []
              },
              {
                id: "09011200",
                label: "09:01 - 12:00",//3301 - 3600 am
                count: 0,
                detail: []
              },
              {
                id: "12011600",
                label: "12:01 - 16:00",//3601 - 4000 pm
                count: 0,
                detail: []
              },
              {
                id: "16011800",
                label: "16:01 - 18:00",//4001 - 4200 pm
                count: 0,
                detail: []
              },
              {
                id: "18012100",
                label: "18:01 - 21:00",//4201 - 4500 pm
                count: 0,
                detail: []
              },
              {
                id: "21012359",
                label: "21:01 - 23:59",//4501 - 4759 pm
                count: 0,
                detail: []
              },
            ]

            var tmpContactLeads24Hours = [
              {
                id: "24000100",
                label: "24:00 - 01:00",//2400 - 2700 am
                headHour: 0,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "01010200",
                label: "01:01 - 02:00",//2400 - 2700 am
                headHour: 1,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "02010300",
                label: "02:01 - 03:00",//2400 - 2700 am
                headHour: 2,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "03010400",
                label: "03:01 - 04:00",//2701 - 3000 am
                headHour: 3,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "04010500",
                label: "04:01 - 05:00",//2701 - 3000 am
                headHour: 4,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "05010600",
                label: "05:01 - 06:00",//2701 - 3000 am
                headHour: 5,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "06010700",
                label: "06:01 - 07:00",//3001 - 3300 am
                headHour: 6,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "07010800",
                label: "07:01 - 08:00",//3001 - 3300 am
                headHour: 7,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "08010900",
                label: "08:01 - 09:00",//3001 - 3300 am
                headHour: 8,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "09011000",
                label: "09:01 - 10:00",//3301 - 3600 am
                headHour: 9,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "10011100",
                label: "10:01 - 11:00",//3301 - 3600 am
                headHour: 10,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "11011200",
                label: "11:01 - 12:00",//3301 - 3600 am
                headHour: 11,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "12011300",
                label: "12:01 - 13:00",//3601 - 4000 pm
                headHour: 12,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "13011400",
                label: "13:01 - 14:00",//3601 - 4000 pm
                headHour: 13,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "14011500",
                label: "14:01 - 15:00",//3601 - 4000 pm
                headHour: 14,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "15011600",
                label: "15:01 - 16:00",//4001 - 4200 pm
                headHour: 15,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "16011700",
                label: "16:01 - 17:00",//4201 - 4500 pm
                headHour: 16,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "17011800",
                label: "17:01 - 18:00",//4501 - 4759 pm
                headHour: 17,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "18011900",
                label: "18:01 - 19:00",//4501 - 4759 pm
                headHour: 18,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "19012000",
                label: "19:01 - 20:00",//4501 - 4759 pm
                headHour: 19,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "20012100",
                label: "20:01 - 21:00",//4501 - 4759 pm
                headHour: 20,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "21012200",
                label: "21:01 - 22:00",//4501 - 4759 pm
                headHour: 21,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "22012300",
                label: "22:01 - 23:00",//4501 - 4759 pm
                headHour: 2,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
              {
                id: "23012359",
                label: "23:01 - 23:59",//4501 - 4759 pm
                headHour: 23,
                count: 0,
                countClosing: 0,
                countSalesOnline: 0,
                detail: []
              },
            ]

            var tmpContactLeadsHourlyShift = [
              {
                id: "07011400",
                label: "07:01 - 14:00",//0701 - 1400 am
                count: 0,
                detail: []
              },
              {
                id: "14012200",
                label: "14:01 - 22:00",//1401 - 2200 am
                count: 0,
                detail: []
              },
              {
                id: "22010700",
                label: "22:01 - 07:00",//2201 - 0700 am
                count: 0,
                detail: []
              }
            ]

            tmpContactLeads.forEach(lead => {
                for(var i=0; i<lead.leads.length; i++){

                  let leadItem = lead.leads[i];

                  var originalTime = Global.formatDateTimeToStrFormat(leadItem.created_at, "hh:mm A");
                  // var timeAndAmPm = originalTime.split(" ");
                  // var time = timeAndAmPm[0];
                  // var amPm = timeAndAmPm[1];
                  // var convertedTime = Global.convertTime12to24(originalTime);

                  // var timeValue = parseInt(convertedTime.replaceAll(":", ""));
                  var timeValue = parseInt(Global.formatDateTimeToStrFormat(leadItem.created_at, "HH:mm").replaceAll(":", ""));

                  console.log("TIMEVALUE", timeValue);

                  if(timeValue <= 2359){
                    timeValue += 2400;
                  }
                  
                  if(timeValue >= 2400 && timeValue <= 2700){
                    tmpContactLeadsHourly[0].count += 1;
                    tmpContactLeadsHourly[0].detail.push(leadItem);
                  }else if(timeValue >= 2701  && timeValue <= 3000){
                    tmpContactLeadsHourly[1].count += 1;
                    tmpContactLeadsHourly[1].detail.push(leadItem);
                  }else if(timeValue >= 3001 && timeValue <= 3300){
                    tmpContactLeadsHourly[2].count += 1;
                    tmpContactLeadsHourly[2].detail.push(leadItem);
                  }else if(timeValue >= 3301 && timeValue <= 3600){
                    tmpContactLeadsHourly[3].count += 1;
                    tmpContactLeadsHourly[3].detail.push(leadItem);
                  }else if(timeValue >= 3601 && timeValue <= 4000){
                    tmpContactLeadsHourly[4].count += 1;
                    tmpContactLeadsHourly[4].detail.push(leadItem);
                  }else if(timeValue >= 4001 && timeValue <= 4200){
                    tmpContactLeadsHourly[5].count += 1;
                    tmpContactLeadsHourly[5].detail.push(leadItem);
                  }else if(timeValue >= 4201 && timeValue <= 4500){
                    tmpContactLeadsHourly[6].count += 1;
                    tmpContactLeadsHourly[6].detail.push(leadItem);
                  }else if(timeValue >= 4501 && timeValue <= 4759){
                    tmpContactLeadsHourly[7].count += 1;
                    tmpContactLeadsHourly[7].detail.push(leadItem);
                  }


                  if(timeValue >= 2400 && timeValue <= 2500){
                    tmpContactLeads24Hours[0].count += 1;
                    tmpContactLeads24Hours[0].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[0].detail.push(leadItem);
                  }else if(timeValue >= 2501  && timeValue <= 2600){
                    tmpContactLeads24Hours[1].count += 1;
                    tmpContactLeads24Hours[1].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[1].detail.push(leadItem);
                  }else if(timeValue >= 2601 && timeValue <= 2700){
                    tmpContactLeads24Hours[2].count += 1;
                    tmpContactLeads24Hours[2].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[2].detail.push(leadItem);
                  }else if(timeValue >= 2701 && timeValue <= 2800){
                    tmpContactLeads24Hours[3].count += 1;
                    tmpContactLeads24Hours[3].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[3].detail.push(leadItem);
                  }else if(timeValue >= 2801 && timeValue <= 2900){
                    tmpContactLeads24Hours[4].count += 1;
                    tmpContactLeads24Hours[4].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[4].detail.push(leadItem);
                  }else if(timeValue >= 2901 && timeValue <= 3000){
                    tmpContactLeads24Hours[5].count += 1;
                    tmpContactLeads24Hours[5].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[5].detail.push(leadItem);
                  }else if(timeValue >= 3001 && timeValue <= 3100){
                    tmpContactLeads24Hours[6].count += 1;
                    tmpContactLeads24Hours[6].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[6].detail.push(leadItem);
                  }else if(timeValue >= 3101 && timeValue <= 3200){
                    tmpContactLeads24Hours[7].count += 1;
                    tmpContactLeads24Hours[7].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[7].detail.push(leadItem);
                  }else if(timeValue >= 3201 && timeValue <= 3300){
                    tmpContactLeads24Hours[8].count += 1;
                    tmpContactLeads24Hours[8].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[8].detail.push(leadItem);
                  }else if(timeValue >= 3301 && timeValue <= 3400){
                    tmpContactLeads24Hours[9].count += 1;
                    tmpContactLeads24Hours[9].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[9].detail.push(leadItem);
                  }else if(timeValue >= 3401 && timeValue <= 3500){
                    tmpContactLeads24Hours[10].count += 1;
                    tmpContactLeads24Hours[10].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[10].detail.push(leadItem);
                  }else if(timeValue >= 3501 && timeValue <= 3600){
                    tmpContactLeads24Hours[11].count += 1;
                    tmpContactLeads24Hours[11].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[11].detail.push(leadItem);
                  }else if(timeValue >= 3601 && timeValue <= 3700){
                    tmpContactLeads24Hours[12].count += 1;
                    tmpContactLeads24Hours[12].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[12].detail.push(leadItem);
                  }else if(timeValue >= 3701 && timeValue <= 3800){
                    tmpContactLeads24Hours[13].count += 1;
                    tmpContactLeads24Hours[13].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[13].detail.push(leadItem);
                  }else if(timeValue >= 3801 && timeValue <= 3900){
                    tmpContactLeads24Hours[14].count += 1;
                    tmpContactLeads24Hours[14].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[14].detail.push(leadItem);
                  }else if(timeValue >= 3901 && timeValue <= 4000){
                    tmpContactLeads24Hours[15].count += 1;
                    tmpContactLeads24Hours[15].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[15].detail.push(leadItem);
                  }else if(timeValue >= 4001 && timeValue <= 4100){
                    tmpContactLeads24Hours[16].count += 1;
                    tmpContactLeads24Hours[16].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[16].detail.push(leadItem);
                  }else if(timeValue >= 4101 && timeValue <= 4100){
                    tmpContactLeads24Hours[17].count += 1;
                    tmpContactLeads24Hours[17].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[17].detail.push(leadItem);
                  }else if(timeValue >= 4201 && timeValue <= 4300){
                    tmpContactLeads24Hours[18].count += 1;
                    tmpContactLeads24Hours[18].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[18].detail.push(leadItem);
                  }else if(timeValue >= 4301 && timeValue <= 4400){
                    tmpContactLeads24Hours[19].count += 1;
                    tmpContactLeads24Hours[19].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[19].detail.push(leadItem);
                  }else if(timeValue >= 4401 && timeValue <= 4500){
                    tmpContactLeads24Hours[20].count += 1;
                    tmpContactLeads24Hours[20].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[20].detail.push(leadItem);
                  }else if(timeValue >= 4501 && timeValue <= 4600){
                    tmpContactLeads24Hours[21].count += 1;
                    tmpContactLeads24Hours[21].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[21].detail.push(leadItem);
                  }else if(timeValue >= 4601 && timeValue <= 4700){
                    tmpContactLeads24Hours[22].count += 1;
                    tmpContactLeads24Hours[22].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[22].detail.push(leadItem);
                  }else if(timeValue >= 4701 && timeValue <= 4800){
                    tmpContactLeads24Hours[23].count += 1;
                    tmpContactLeads24Hours[23].countClosing += leadItem.isClosing === true ? 1 : 0;
                    tmpContactLeads24Hours[23].detail.push(leadItem);
                  }

                  if(timeValue >= 3101 && timeValue <= 3800){
                    tmpContactLeadsHourlyShift[0].count += 1;
                    tmpContactLeadsHourlyShift[0].detail.push(leadItem);
                  }else if(timeValue >= 3801 && timeValue <= 4600){
                    tmpContactLeadsHourlyShift[1].count += 1;
                    tmpContactLeadsHourlyShift[1].detail.push(leadItem);
                  }else if(timeValue >= 4601 | timeValue <= 3100){
                    tmpContactLeadsHourlyShift[2].count += 1;
                    tmpContactLeadsHourlyShift[2].detail.push(leadItem);
                  }

                  lead.leads[i].createdAt = originalTime;
                  tmpContactLeadsAll.push(lead.leads[i]);

                
                }
                lead.leadsCount = lead.leads.length;
            });

            console.log("CONTACTLEADSALL", tmpContactLeads24Hours);

            this.setState(
              {
                // contactLeads: tmpContactLeads, 
                // contactLeadsAll: tmpContactLeadsAll, 
                contactLeadsHourly: tmpContactLeadsHourly,
                contactLeads24Hours: tmpContactLeads24Hours,
                contactLeadsHourlyShift: tmpContactLeadsHourlyShift,
                isLoading: false,
              }
            ,() => {
              this.getLoginLogs();
            });
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };

    getLoginLogs = () => {
      console.log("STARTDATE : ",this.state.startDate);
      var userData = Global.get();
      console.log("USERDATA : ", userData);
      console.log("USERDATA", userData);

      const params = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        appProfileId: this.state.appProfileId,
        shift: "PAGI",
        isSalesOnlineOnly: true,
        inlcudeShift3: true
        // token: "07d0b91e771752005d94ceb5c5efdc0a"
      }

      this.setState({loginLogs: [], isLoading: true}, () => {
          axios
          .post(Api.loginMonitoring(), params, Api.getRequestHeader())
          .then((response) => {
              console.log("loginLogs : ", response.data);
              
              var tmpLogs = response.data.data.logs;
              var tmpLeads24Hours = [...this.state.contactLeads24Hours];
              let formatted = [];

              tmpLogs.forEach(log => {

                let loginDateTime = log.created_at;
                let logoutDateTime = log.updated_at;

                let formattedLoginDate = "";
                let formattedLogoutDate = "";

                formattedLoginDate = Global.formatDateTimeToStrFormat(loginDateTime, "YYYY-MM-DD");
                formattedLogoutDate = Global.formatDateTimeToStrFormat(logoutDateTime, "YYYY-MM-DD");
                console.log("FORMATTED_LOGINDATE", formattedLoginDate);

                

                // if(formattedLoginDate !== this.state.startDate){
                //   let newLoginDateTime = moment(log.created_at).tz("UTC").add(moment.duration({"hours": 2}));
                //   console.log("LOG__KENA", loginDateTime, "ADD HOURS", newLoginDateTime);
                //   loginDateTime = newLoginDateTime;
                // }

                if(log.logoutTime !== null && formattedLogoutDate !== this.state.startDate){
                  logoutDateTime = moment(log.updated_at).tz("UTC").add(2, "hours");
                }

                var originalStartTime = Global.formatDateTimeToStrFormat(loginDateTime, "hh:mm A");
                var convertedStartTime = Global.convertTime12to24(originalStartTime);
                var startTimeValue = parseInt(convertedStartTime.replaceAll(":", ""));
                var headHourLogin = parseInt(convertedStartTime.substring(0,2));

                var originalEndTime = Global.formatDateTimeToStrFormat(logoutDateTime, "hh:mm A");
                var convertedEndTime = Global.convertTime12to24(originalEndTime);
                var endTimeValue = parseInt(convertedEndTime.replaceAll(":", ""));
                var headHourLogout = parseInt(convertedEndTime.substring(0,2));

                let isLoggedOut = log.logoutTime !== null;

                console.log("TIMEVALUE", startTimeValue, endTimeValue);

                if(startTimeValue <= 2359){
                  startTimeValue += 2400;
                  console.log("LOG__add2400", log, startTimeValue, headHourLogin, headHourLogout, convertedEndTime, originalEndTime);
                }

                if(endTimeValue <= 2359){
                  endTimeValue += 2400;
                }

                // if(headHourLogin === 0){
                //   headHourLogin = 1;
                // }

                // if(headHourLogout === 0){
                //   headHourLogout = 1;
                // }

                let existedLog = formatted.find(item => log.lavenderUserId === item.userId);

                log.startTimeValue = startTimeValue;
                log.endTimeValue = endTimeValue;
                log.isLoggedOut = isLoggedOut;
                log.headHourLogin = headHourLogin;
                log.headHourLogout = headHourLogout;

                if(existedLog){
                    existedLog.rows.push(log);
                    existedLog.lastLoginState = log.state;
                }else{
                    formatted.push({
                        userId: log.lavenderUserId,
                        userName: log.name,
                        lastLoginState: log.state,
                        rows: [
                            log
                        ]
                    });
                }
              });

              // this.calculatePerSectionHours(tmpLeads24Hours, []);

              formatted.forEach(format => {
                this.calculatePerSectionHours(tmpLeads24Hours, format.rows);
              });
              
              this.setState({loginLogs: formatted, contactLeads24Hours: tmpLeads24Hours, isLoading: false});
          })
          .catch((error) => {
              console.error("There was an error!", error);
          });
      });

    };

    calculatePerSectionHours = (leadsByHours, loginLogs) => {

      let now = Global.convertTime12to24(moment(new Date()).format("hh:mm A"));
      let hourNow = parseInt(now.substring(0,2));

      console.log("LOG__HOURNOW", hourNow);

      let existedHours = [];

      if(loginLogs.length === 1){

        let formattedLoginDate = "";
        let formattedLogoutDate = "";

        formattedLoginDate = Global.formatDateTimeToStrFormat(loginLogs[0].created_at, "YYYY-MM-DD");
        formattedLogoutDate = Global.formatDateTimeToStrFormat(loginLogs[0].updated_at, "YYYY-MM-DD");
        if(formattedLoginDate !== this.state.startDate){
          console.log("LOG__TIDAKSAMA", loginLogs[0]);
          loginLogs[0].headHourLogin = 0;
        }

        if(formattedLogoutDate !== this.state.startDate){
          console.log("LOG__TIDAKSAMA", loginLogs[0]);
          if(loginLogs[0].headHourLogout <= 24){
            loginLogs[0].headHourLogout = -1;
          }else{
            loginLogs[0].headHourLogout = 0;
          }
          
        }

        if(loginLogs[0].logoutTime === null){
          console.log("LOG__HOUR1", "LOGOUT NULL", hourNow, loginLogs[0]);
          for(let i=loginLogs[0].headHourLogin; i<=hourNow; i++){
            let findByHeadHour = leadsByHours.find(byHour => byHour.headHour === i);

            if(findByHeadHour) findByHeadHour.countSalesOnline += existedHours.filter(hour => hour === i).length > 0 ? 0 : 1;
            // if(findByHeadHour) findByHeadHour.countSalesOnline += 1;
            existedHours.push(loginLogs[0].headHourLogin);
          }
        } else{
          console.log("LOG__HOUR1", "LOGOUT NOT NULL", hourNow, loginLogs[0]);
          for(let i=loginLogs[0].headHourLogin; i<=loginLogs[0].headHourLogout; i++){
            let findByHeadHour = leadsByHours.find(byHour => byHour.headHour === i);

            if(findByHeadHour) findByHeadHour.countSalesOnline += existedHours.filter(hour => hour === i).length > 0 ? 0 : 1;
            // if(findByHeadHour) findByHeadHour.countSalesOnline += 1;
            existedHours.push(loginLogs[0].headHourLogin);
          }
        }                 
      }else{
        loginLogs.forEach(log => {
          let formattedLoginDate = "";
          let formattedLogoutDate = "";

          formattedLoginDate = Global.formatDateTimeToStrFormat(log.created_at, "YYYY-MM-DD");
          formattedLogoutDate = Global.formatDateTimeToStrFormat(log.updated_at, "YYYY-MM-DD");
          if(formattedLoginDate !== this.state.startDate){
            console.log("LOG__TIDAKSAMA", log);
            log.headHourLogin = 0;
          }

          if(log.logoutTime === null){
            console.log("LOG__HOUR2", "LOGOUT NULL", hourNow);
            for(let i=log.headHourLogin; i<=hourNow; i++){
              let findByHeadHour = leadsByHours.find(byHour => byHour.headHour === i);

              if(findByHeadHour) findByHeadHour.countSalesOnline += existedHours.filter(hour => hour === i).length > 0 ? 0 :  1;
              // if(findByHeadHour) findByHeadHour.countSalesOnline += 1;
              existedHours.push(log.headHourLogin);
            }
          }else{
            console.log("LOG__HOUR2", "LOGOUT NOT NULL", hourNow);
            for(let i=log.headHourLogin; i<log.headHourLogout; i++){
              let findByHeadHour = leadsByHours.find(byHour => byHour.headHour === i);

              if(findByHeadHour) findByHeadHour.countSalesOnline += existedHours.filter(hour => hour === i).length > 0 ? 0 : 1;
              // if(findByHeadHour) findByHeadHour.countSalesOnline += 1;
              existedHours.push(log.headHourLogin);
            }
          }
        });
      }

      console.log("LOG__HOUR_", existedHours);
      console.log("LOG__HOUR_", loginLogs);


    }

    convertTime12to24 = (time12h) => {
      const [time, modifier] = time12h.split(" ");
      
      let [hours, minutes, seconds] = time.split(":");
      
      if (hours === "12") {
        hours = "00";
      }
      
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
      
      return `${hours}:${minutes}:${seconds}`;
    };

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    columns = [
        { id: 'salesName', label: 'Sales', minWidth: 170 },
        { id: 'leadsCount', label: 'Jumlah Leads', minWidth: 100 }
    ];

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.getContactLeads);
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    render() { 
        return ( 
          <Paper style={{padding:20}}>
                <div className="container-fluid">
                  <div className="panel panel-default">
                      <div className="panel-heading">
                          <div className="panel-title">
                              {/* <h1>Setup Contact</h1> */}
                          </div>
                      </div>
                      <div className="panel-body">
                          <div className="row align-items-center">
                              <div className="col-2">
                                  {/* <strong>Total {this.state.contactLeads.length} Salesman</strong> */}
                              </div>
                              <div className="col-2">
                              {/* {
                                  Global.get().admin === true ? <ExportLeadsReport leadsReportData={this.state.contactLeads} /> : <></>
                              } */}
                              
                              </div>
                              <div className="col-7 text-right" style={{borderRadius: 10, padding: 5}}>
                                <DateRangePicker onDateChanged={this.handleDateChanged} />
                                  {/* <span style={{marginTop: 5,}}><strong>Leads dari :</strong> &nbsp;</span>
                                  
                                  <TextField
                                      id="dateStart"
                                      // label="Tanggal"
                                      type="date"
                                      defaultValue={this.state.startDate}
                                      onChange={this.handleStartDateChange}
                                      // className={classes.textField}
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                  />
                                  &nbsp; <strong style={{marginTop: 5,}}>Sampai</strong> &nbsp;
                                  <TextField
                                      id="dateEnd"
                                      // label="Tanggal"
                                      type="date"
                                      defaultValue={this.state.endDate}
                                      onChange={this.handleEndDateChange}
                                      // className={classes.textField}
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                    /> */}
                              </div>
                              <div className="col-1">
                              {
                                    this.state.isLoading === true ? 
                                        (<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
                                        (<div onClick={() => {this.getContactLeads()}} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
                                }
                            </div>
                          </div>
                          <br />
                          <h5>
                            Perolehan leads berdasarkan selang waktu
                          </h5>
                          <br />
                          <Paper className="root">
                              <TableContainer>
                                  <Table aria-label="sticky table" size='small'>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell />
                                      {this.state.contactLeadsHourly.map((column) => (
                                          <TableCell
                                              key={column.id}
                                              align={column.align}
                                          >
                                          <strong>{column.label}</strong>
                                          </TableCell>
                                      ))}
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell />
                                        {this.state.contactLeadsHourly.map((column) => (
                                            <TableCell
                                                key={column.id+"a"}
                                                align={column.align}
                                            >
                                            {column.count}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                  </TableBody>
                                  </Table>
                              </TableContainer>
                              
                          </Paper>
                          <br />

                          <br />
                          <h5>
                            Perolehan leads Tiap Jam
                          </h5>
                          <br />
                          {/* <Paper className="root"> */}
                            <div className="row">
                              <div className="col-5">
                                <Paper>
                                <TableContainer>
                                    <Table aria-label="sticky table" size="small">
                                      <TableHead>
                                          <TableRow>
                                              <TableCell width={"50%"}>Jam (AM)</TableCell>
                                              <TableCell>Jumlah Leads</TableCell>
                                              <TableCell>Jumlah Sales</TableCell>
                                              <TableCell>Closing Rate</TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                      {this.state.contactLeads24Hours.slice(0,12).map((column) => (
                                        <TableRow>
                                          <TableCell
                                                  key={column.id}
                                                  width={500}
                                                  align='left'
                                                  // align={column.align}
                                              >
                                                <strong>{column.label}</strong>
                                              </TableCell>
                                              <TableCell
                                                  key={column.id+"a"}
                                                  align={"right"}
                                              >
                                              {column.count}
                                              </TableCell>
                                              <TableCell
                                                  key={column.id+"b"}
                                                  align={"right"}
                                              >
                                              {column.countSalesOnline}
                                              </TableCell>
                                              <TableCell
                                                  key={column.id+"c"}
                                                  align={"right"}
                                              >
                                              {column.count > 0 ? (column.countClosing/column.count*100).toFixed(1) : 0}%
                                              </TableCell>
                                        </TableRow>
                                            ))}
                                      </TableBody>
                                    </Table>
                                </TableContainer>
                                </Paper>
                              </div>
                              {/* <div className="col-1"></div> */}
                              <div className="col-6">
                                <Paper>
                                <TableContainer>
                                    <Table aria-label="sticky table" size={"small"}>
                                      <TableHead>
                                          <TableRow>
                                              <TableCell width={"50%"}>Jam (PM)</TableCell>
                                              <TableCell>Jumlah Leads</TableCell>
                                              <TableCell>Jumlah Sales</TableCell>
                                              <TableCell>Closing Rate</TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                      {this.state.contactLeads24Hours.slice(12).map((column) => (
                                        <TableRow>
                                          <TableCell
                                                  key={column.id}
                                                  width={500}
                                                  align='left'
                                                  // align={column.align}
                                              >
                                                <strong>{column.label}</strong>
                                              </TableCell>
                                              <TableCell
                                                  key={column.id+"a"}
                                                  align={"right"}
                                              >
                                              {column.count}
                                              </TableCell>
                                              <TableCell
                                                  key={column.id+"b"}
                                                  align={"right"}
                                              >
                                              {column.countSalesOnline}
                                              </TableCell>
                                              <TableCell
                                                  key={column.id+"c"}
                                                  align={"right"}
                                              >
                                              {column.count > 0 ? (column.countClosing/column.count*100).toFixed(1) : 0}%
                                              </TableCell>
                                        </TableRow>
                                            ))}
                                      </TableBody>
                                    </Table>
                                </TableContainer>
                                </Paper>
                              </div>
                            </div>
                          {/* </Paper> */}
                          <br />
                          {/* <h5>
                            Sales Online
                          </h5>
                          <br />
                          <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="20px" />
                                        <TableCell>User</TableCell>
                                        <TableCell>Login Count</TableCell>
                                        <TableCell>Last Login State</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loginLogs.map((row) => {
                                    return (
                                        <Row key={row.salesId} row={row} />
                                    );
                                    })}
                                </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper> */}

                          <h5>
                            Perolehan leads berdasarkan Shift
                          </h5>
                          <br />
                          <Paper className="root">
                              <TableContainer>
                                  <Table aria-label="sticky table" size={"small"}>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell />
                                      {this.state.contactLeadsHourlyShift.map((column) => (
                                          <TableCell
                                              key={column.id}
                                              align={column.align}
                                          >
                                          <strong>{column.label}</strong>
                                          </TableCell>
                                      ))}
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell />
                                        {this.state.contactLeadsHourlyShift.map((column) => (
                                            <TableCell
                                                key={column.id+"a"}
                                                align={column.align}
                                            >
                                            {column.count}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                  </TableBody>
                                  </Table>
                              </TableContainer>
                              
                          </Paper>
                          
                      </div>
                      <div className="panel-footer">
                          
                      </div>
                  </div>
                </div>
              </Paper>
         );
    }
}
 
export default ContactLeadsReportHourly;

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell width="20px">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.userName}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.rows.length}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.lastLoginState === "ONLINE" ? <span className='text-warning font-bold'><ConnectWithoutContact/> ONLINE</span> : <span className='text-secondary font-bold'><ConnectWithoutContact/> OFFLINE</span>}
        </TableCell> 
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/* <Typography variant="h6" gutterBottom component="div">
                Riwayat Login
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Login</strong></TableCell>
                    <TableCell><strong>Logout</strong></TableCell>
                    <TableCell><strong>Status</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.rows.map((historyRow) => (
                    <TableRow key={historyRow.id}>
                      <TableCell align='left'>
                        {Global.formatDateTimeToStrDateTime24(historyRow.created_at)}
                      </TableCell>
                      <TableCell align="left">
                          {historyRow.logoutTime ? Global.formatDateTimeToStrDateTime24(historyRow.updated_at) : "--:--:--"}
                      </TableCell>
                      <TableCell align='left'>{historyRow.state}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// const useRowStyles = makeStyles({
//     root: {
//       '& > *': {
//         borderBottom: 'unset',
//       },
//     },
// });

// function Row(props) {
//     const { row } = props;
//     const [open, setOpen] = React.useState(false);
//     const classes = useRowStyles();
//     // const classes = {root: {}};
  
//     return (
//       <React.Fragment>
//         <TableRow className={classes.root}>
//           <TableCell>
//             <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
//               {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//             </IconButton>
//           </TableCell>
//           <TableCell component="th" scope="row">
//             {row.salesName}
//           </TableCell>
//           <TableCell align="left">{row.leadsCount} leads</TableCell>
//         </TableRow>
//         <TableRow>
//           <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
//             <Collapse in={open} timeout="auto" unmountOnExit>
//               <Box margin={1}>
//                 <Typography variant="h6" gutterBottom component="div">
//                   Leads Detail
//                 </Typography>
//                 <Table size="small" aria-label="purchases">
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>Nama</TableCell>
//                       {
//                           Global.get().admin === true ? <TableCell>Nomor WhatsApp</TableCell> : <></>
//                       }
//                       <TableCell>Tanggal lead</TableCell>
//                       <TableCell>Chat Terakhir</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {row.leads.map((historyRow) => (
//                       <TableRow key={historyRow.id}>
//                         <TableCell component="th" scope="row">
//                           {historyRow.fromName}
//                         </TableCell>
//                         {
//                             Global.get().admin === true ? <TableCell>{historyRow.from}</TableCell> : <></>
//                         }
//                         <TableCell align="left">{historyRow.createdAt}</TableCell>
//                         <TableCell align="left">
//                           {Global.formatDateTimeToStrDateTime24(historyRow.lastMessageReceivedAt)}
//                         </TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </Box>
//             </Collapse>
//           </TableCell>
//         </TableRow>
//       </React.Fragment>
//     );
//   }

  // function MyDateRangePicker(props){
  //   const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
  //   const [startDate, endDate] = dateRange;
  //   const [message, setMessage] = React.useState("");
  //   return (
  //     <>
  //       <DatePicker
  //         readOnly={false}
  //         selectsRange={true}
  //         startDate={startDate}
  //         endDate={endDate}
  //         dateFormat="d-M-yyyy"
  //         onChange={(update) => {
  //           console.log("RANGE", update);
            
  //           if(update[0] !== null && update[1] !== null){
  //             let startDate = moment(update[0]);
  //             let endDate = moment(update[1]);
  //             let diffs = Math.abs(startDate.diff(endDate, "days"));
  //             console.log("DIFFS", diffs);
  //             if(diffs > 35){
  //               setDateRange([null, null]);
  //               setMessage("Rentang tanggal max 35 hari");
  //               return
  //             }
  //           }
            
  //           setDateRange(update);
  //           setMessage("");
  //           if(update[0] !== null && update[1] !== null){
  //             props.onDateChanged(update);
  //           }

  //         }}
  //         isClearable={false}
  //         popperPlacement="top-end"
  //         popperModifiers={[
  //           {
  //             name: "offset",
  //             options: {
  //               offset: [5, 10],
  //             },
  //           },
  //           {
  //             name: "preventOverflow",
  //             options: {
  //               rootBoundary: "viewport",
  //               tether: false,
  //               altAxis: true,
  //             },
  //           },
  //         ]}
  //       />
  //       <div className="text-danger">
  //         {message}
  //       </div>
  //     </>
  //   );
  // }