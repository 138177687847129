import React, { Component } from 'react';
import { makeStyles } from '@mui/styles';
import {Paper, Tabs, Tab, Typography, Box, Container} from '@mui/material';
import { Link } from 'react-router-dom';
import Global from "../../customlib/Global";
import TeleFuReport from '../ContactLeadsReport/TeleFuReport';
import TeleChatByCustomer from '../ContactLeadsReport/TeleChatByCustomer';
import EventReminderReport from '../ContactLeadsReport/EventReminderReport';
import TeleResponseTimeReportAverage from '../SalesResponseTimeReport/TeleResponseTimeReportAverage';
import TeleDiChatCustomerResponseTimeReportAverage from '../SalesResponseTimeReport/TeleDiChatCustomerResponseTimeReportAverage';
import TeleFuEffectivityRate from '../SalesResponseTimeReport/TeleFuEffectivityRate';
import TeleFuHsmMarketingEffectivityRate from '../SalesResponseTimeReport/TeleFuHsmMarketingEffectivityRate';
import HsmTeleEffectivityRate from '../SalesResponseTimeReport/HsmTeleEffectivityRate';
import ResumeKedisiplinanFuTele from '../KedisiplinanFuTele/ResumeKedisiplinanFuTele';
import DetailKedisiplinanFuTele from '../KedisiplinanFuTele/DetailKedisiplinanFuTele';
import { ArrowBack, ChevronLeft, ChevronRight, Warning } from '@mui/icons-material';
import TeleDiChatOlehCustomerResponseTimeReportFirstTime from '../SalesResponseTimeReport/TeleDiChatOlehCustomerResponseTimeReportFirstTime';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

class DashboardTele extends Component {

    state = { 
        value : 0,
    }

    useStyles = makeStyles({
        root: {
          flexGrow: 1
        },
      });

    // classes = useStyles();
    // const [value, setValue] = React.useState(0);

    componentDidMount() {
        this.setState({value: 0});
    }
    

    handleChange = (event, newValue) => {
        // setValue(newValue);
        this.setState({value:newValue});
    };

    isAllow = () => {
        return Global.get.admin || 
            Global.isTeleAdmin() || Global.isTeleCutomer() || Global.isTeleUser() ||
            Global.isAdminTeleUser || Global.isAdminEpUser();
    }

    render() { 
        return ( 
            <>
            <Paper className={this.useStyles.root} style={{margin: "10px", padding: "5px", backgroundColor: "#e7eef4"}}>
                <div className="container-fluid text-center">
                  <div className="row">
                      <div className="col-11 text-left" style={{paddingLeft: "30px"}}><h1>Dashboard Tele</h1></div>
                      <div className="col-1 text-right">
                          <Link to="/">
                              <div className="btn btn-danger">X</div>
                          </Link>
                      </div>
                  </div>
                </div>
                
            </Paper>
            {
                !this.isAllow() ? (
                    <Box sx={{width: "100%",margin: "20px", border: "solid 0px"}}>
                        <Box textAlign={"center"}>
                            <Warning color='error' />
                            <h4>Halaman ini bukan untuk Anda</h4>
                            <Link to="/"><ChevronLeft /> Kembali</Link>
                        </Box>
                    </Box>
                ) : 
                (
                    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: "100%" }}>
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            // centered
                            variant='scrollable'
                            orientation='vertical'
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                            visibleScrollbar
                        >
                            <Tab key="tab0" label="Tele FU Report" wrapped />
                            <Tab key="tab1" label="Tele Di Chat Oleh Customer" wrapped />
                            <Tab key="tab2" label="ER Report" wrapped />
                            <Tab key="tab3" label="Avrage Tele/Recycle" wrapped />
                            <Tab key="tab4" label="First Respon Tele Di Chat Oleh Customer" wrapped />
                            <Tab key="tab5" label="Average Tele Di Chat Oleh Customer" wrapped />
                            <Tab key="tab6" label="Efektivitas FU Tele" wrapped />
                            <Tab key="tab7" label="Efektivitas FU Tele HSM Marketing" wrapped />
                            <Tab key="tab8" label="Efektivitas HSM Tele" wrapped />
                            <Tab key="tab9" label="Kedisiplinan FU Tele" wrapped />
                            <Tab key="tab10" label="Resume Kedisiplinan FU Tele" wrapped />
                            {/* <Tab key="tab2" label="Cancel By Jam" wrapped /> */}
                        </Tabs>
                        <TabPanel value={this.state.value} index={0} style={{width: "100%"}}>
                            <TeleFuReport />
                        </TabPanel>
                        <TabPanel value={this.state.value} index={1} style={{width: "100%"}}>
                            <TeleChatByCustomer />
                        </TabPanel>
                        <TabPanel value={this.state.value} index={2} style={{width: "100%"}}>
                            <EventReminderReport />
                        </TabPanel>
                        <TabPanel value={this.state.value} index={3} style={{width: "100%"}}>
                            <TeleResponseTimeReportAverage />
                        </TabPanel>
                        <TabPanel value={this.state.value} index={4} style={{width: "100%"}}>
                            <TeleDiChatOlehCustomerResponseTimeReportFirstTime />
                        </TabPanel>
                        <TabPanel value={this.state.value} index={5} style={{width: "100%"}}>
                            <TeleDiChatCustomerResponseTimeReportAverage />
                        </TabPanel>
                        <TabPanel value={this.state.value} index={6} style={{width: "100%"}}>
                            <TeleFuEffectivityRate />
                        </TabPanel>
                        <TabPanel value={this.state.value} index={7} style={{width: "100%"}}>
                            <TeleFuHsmMarketingEffectivityRate />
                        </TabPanel>
                        <TabPanel value={this.state.value} index={8} style={{width: "100%"}}>
                            <HsmTeleEffectivityRate />
                        </TabPanel>
                        <TabPanel value={this.state.value} index={9} style={{width: "100%"}}>
                            <DetailKedisiplinanFuTele />
                        </TabPanel>
                        <TabPanel value={this.state.value} index={10} style={{width: "100%"}}>
                            <ResumeKedisiplinanFuTele />
                        </TabPanel>
                    </Box>
                )
            }   
            </>
         );
    }
}
 
export default DashboardTele;